const getSubordinates = user =>
  user.children
    ?.map(user => {
      if (Array.isArray(user.children)) {
        return [user, ...getSubordinates(user)]
      }

      return user
    })
    .reduce((acc, arr) => [...acc, ...(Array.isArray(arr) ? arr : [arr])], [])
    .sort((a, b) => {
      if (a.username < b.username) {
        return -1
      }
      if (a.username > b.username) {
        return 1
      }
      return 0
    })

export default getSubordinates
