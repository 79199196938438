import call from '../../utils/api'

export const SET_TASK_TYPES = 'SET_TASK_TYPES'
export const SET_TASK_SUB_TYPES = 'SET_TASK_SUB_TYPES'
export const SET_TASK_STATUSES = 'SET_TASK_STATUSES'
export const SET_DASHBOARD_SUBORDINATE = 'SET_DASHBOARD_SUBORDINATE'
export const REALOD_OVERVIEW = 'REALOD_OVERVIEW'
export const REALOD_TICKETS = 'REALOD_TICKETS'

export const getTaskTypes = () => {
  return dispatch => {
    call({
      route: 'ticketing.getTaskTypes',
    })
      .then(response => {
        dispatch({
          type: 'SET_TASK_TYPES',
          payload: response.task_types,
        })
      })
      .catch(() => {
        dispatch({
          type: 'SET_TASK_TYPES',
          payload: [],
        })
      })
  }
}

export const getTaskSubTypes = ({ type_id }) => {
  return dispatch => {
    call({
      route: 'ticketing.getTaskSubTypes',
      query: {
        type_id,
      },
    })
      .then(response => {
        dispatch({
          type: 'SET_TASK_SUB_TYPES',
          payload: response.task_sub_types,
        })
      })
      .catch(() => {
        dispatch({
          type: 'SET_TASK_SUB_TYPES',
          payload: [],
        })
      })
  }
}

export const getTaskStatuses = () => {
  return dispatch => {
    call({
      route: 'ticketing.getTaskStatus',
    })
      .then(response => {
        dispatch({
          type: 'SET_TASK_STATUSES',
          payload: response.task_status,
        })
      })
      .catch(() => {
        dispatch({
          type: 'SET_TASK_STATUSES',
          payload: [],
        })
      })
  }
}

export const setSubordinate = payload => {
  return {
    type: 'SET_DASHBOARD_SUBORDINATE',
    payload: { ...payload },
  }
}

export const reloadOverview = toggle => {
  return {
    type: 'REALOD_OVERVIEW',
    payload: toggle,
  }
}

export const reloadTickets = toggle => {
  return {
    type: 'REALOD_TICKETS',
    payload: toggle,
  }
}
