import { combineReducers } from 'redux'

import itineraryStatus from './reducer_itinerary_state'
import ItineraryDataReducer from './reducer_itinerary_data'
import itineraryCosted from './reducer_itinerary_costing'
import itinerarySum from './reducer_itinerary_sum_costing'
import itineraryExtraCosts from './reducer_itinerary_extra_costing'
import trailCustomer from './reducer_trail_customer'
import trailDetails from './reducer_trail_details'
import IterMastersReducer from './reducer_iter_masters'
import IterCreateItinerary from './reducer_create_itinerary'
import ItineraryChecklist from './reducer_itinerary_checklist'
import ItineraryInvoices from './reducer_invoices'
import ItineraryRefunds from './reducer_costing_refunds'
import CommunicationMethod from './reducer_communication_method'

import VirtualCreditCardDetail from './reduces_vcc_info'
import CardStatus from './reducer_card_status'

const ItineraryPageReducer = combineReducers({
  masters: IterMastersReducer,
  createItinerary: IterCreateItinerary,
  itinerary: ItineraryDataReducer,
  trailCustomer: trailCustomer,
  itineraryCosted: itineraryCosted,
  itinerarySum: itinerarySum,
  itineraryExtraCosts: itineraryExtraCosts,
  itineraryStatus: itineraryStatus,
  trailDetails: trailDetails,
  checklist: ItineraryChecklist,
  invoices: ItineraryInvoices,
  refunds: ItineraryRefunds,
  communicationMethod: CommunicationMethod,
  vccDetails: VirtualCreditCardDetail,
  cardWiseAllowedStatus: CardStatus,
})

export default ItineraryPageReducer
