import { combineReducers } from 'redux'

import user from './user'
import presales from './presales'
import master from './master'
import ticketing from './ticketing'
import callNotes from './callNotes'
import newUser from './newUser'

const rootReducer = combineReducers({
  master,
  user,
  presales,
  ticketing,
  callNotes,
  newUser,
})

export default rootReducer
