const requestPermission = () => {
  if (!('Notification' in window)) {
    // eslint-disable-next-line no-console
    console.log('This browser does not support notifications.')
  } else {
    Promise.resolve(Notification.requestPermission()).then(() => {})
  }
}

const notify = (options, onClick = null) => {
  Notification.requestPermission(result => {
    if (result === 'granted') {
      const { title = 'New notification', ...restOptions } = options
      let notification = new Notification(title, {
        ...restOptions,
      })
      notification.onclick = () => onClick && onClick()
    }
  })
}

export default {
  requestPermission,
  notify,
}
