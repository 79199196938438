import Axios from 'axios'
import getSubordinates from '../../utils/getSubordinates'

export const SET_USER_DETAILS = 'SET_USER_DETAILS'

export const setUserDetails = (payload, reload = false) => dispatch => {
  Axios.get(`${window.env.V3_API_URL}user`, {
    headers: {
      Authorization: `${payload.token_type} ${payload.access_token}`,
    },
  }).then(response => {
    dispatch({
      type: 'SET_USER_DETAILS',
      payload: {
        ...response.data,
        ...payload,
        children: getSubordinates(response.data),
      },
    })
    if (reload) {
      window?.location?.reload()
    }
  })
}

export const login = (username, password) => dispatch => {
  Axios.post(`${window.env.API_URL}oauth/token`, {
    username,
    password,
    grant_type: 'password',
    client_id: '914e9fd8-ba34-403e-80e9-ffb5485ec4c5',
    client_secret: 'v5cmwQqUhJLtMPY8aGFNbDc8wKWnT1vKNtjPIMyg',
  })
    .then(response => dispatch(setUserDetails(response.data)))
    .catch(() => {})
}
