import React from 'react'
import ReactDOM from 'react-dom'

import loadable from './components/loadable'

import { BrowserRouter, HashRouter } from 'react-router-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import ItineraryPageReducer from './itinerary/reducers/reducer_itinerary'
import ThunkMiddleware from 'redux-thunk'

const AgentsCmsLayout = loadable(() => import(/* webpackChunkName: "agentsCms" */ './cms/agents_cms_layout'))
const ProductCmsApp = loadable(() => import(/* webpackChunkName: "productCms" */ './cms/product_cms_layout'))
const GuidesCmsLayout = loadable(() => import(/* webpackChunkName: "guidesCms" */ './cms/guides_cms_layout'))
const DealsCmsLayout = loadable(() => import(/* webpackChunkName: "dealsCms" */ './cms/deals_cms_layout'))
const ProductIntelApp = loadable(() => import(/* webpackChunkName: "productIntel" */ './cms/product_intel_layout'))
const SeoCmsApp = loadable(() => import(/* webpackChunkName: "seoCms" */ './cms/seo_cms_layout'))
const PricingCmsLayout = loadable(() => import(/* webpackChunkName: "pricingCms" */ './cms/pricing_cms_layout'))
const ImagesCmsLayout = loadable(() => import(/* webpackChunkName: "imagesCms" */ './cms/manage_cms_images_layout'))

const CreateVccUploadRequest = loadable(() =>
  import(/* webpackChunkName: "createVccUploadRequest" */ './accounting/create_vcc_upload_request')
)
const GmailInboxWrapper = loadable(() => import(/* webpackChunkName: "gmailInbox" */ './inbox/inbox_wrapper'))
const EmailSignature = loadable(() => import(/* webpackChunkName: "emailSignature" */ './email/EmailSignature'))

const GstInvoice = loadable(() => import(/* webpackChunkName: "gstInvoice" */ './finance/gstInvoice'))

const SalesFunnel = loadable(() => import(/* webpackChunkName: "salesFunnel" */ './sales/sales_funnel'))
const ItineraryWrapper = loadable(() => import(/* webpackChunkName: "itinerary" */ './itinerary/index.js'))
const ChFeedbackForm = loadable(() => import(/* webpackChunkName: "chFeedback" */ './ch/feedback/FeedbackForm'))

const RefundsTable = loadable(() => import(/* webpackChunkName: "refundsRequestTable" */ './refunds/refundsTable'))
const TCS = loadable(() => import(/* webpackChunkName: "trailTcs" */ './finance/tcs'))

const PDFViewer = loadable(() => import(/* webpackChunkName: "pdfViewer" */ '@pyt/apollo/components/pdfViewer'))

const TicketingOverview = loadable(() =>
  import(/* webpackChunkName: "ticketingOverview" */ '../ticketing/components/overviewDashboard')
)

let $root = document.getElementById('root')

let $cmsProductRoot = document.getElementById('cms-product-root')
let $cmsGuidesRoot = document.getElementById('cms-guides-root')
let $cmsDealsRoot = document.getElementById('cms-deals-root')
let $productIntelRoot = document.getElementById('product-intel-root')
let $seoCMSRoot = document.getElementById('seo-root')
let $pricingCMSRoot = document.getElementById('cms-pricing-root')
let $CMSImagesRoot = document.getElementById('cms-images-root')
let $CMSAgentsRoot = document.getElementById('cms-agents-root')

let $GmailInboxRoot = document.getElementById('gmail-inbox-root')
let $SalesFunnelRoot = document.getElementById('sales_pipeline')
let $EmailSignatureRoot = document.getElementById('email-signature-root')
let $RefundsRequestTable = document.getElementById('refunds-requests-table')
let $tcs = document.getElementById('trail-tcs-root')
let $pdfViewer = document.getElementById('pdf-viewer')
let $gstInvoice = document.getElementById('gst-invoice')

let $createVCCUploadRequest = document.getElementById('create-virtual-card-upload-request')
let $chFeedbackForm = document.getElementById('ch-new-feedback-form')

/**
 * Itinerary
 */
if ($root) {
  ReactDOM.render(
    <HashRouter>
      <Provider store={applyMiddleware(ThunkMiddleware)(createStore)(ItineraryPageReducer)}>
        <div className="App">
          <div className="primary-container">
            <ItineraryWrapper />
          </div>
        </div>
      </Provider>
    </HashRouter>,
    $root
  )
}

/**
 * CMS
 */
if ($cmsProductRoot) {
  ReactDOM.render(
    <BrowserRouter>
      <ProductCmsApp />
    </BrowserRouter>,
    $cmsProductRoot
  )
}
if ($cmsGuidesRoot) {
  ReactDOM.render(<GuidesCmsLayout />, $cmsGuidesRoot)
}
if ($cmsDealsRoot) {
  ReactDOM.render(<DealsCmsLayout />, $cmsDealsRoot)
}
if ($productIntelRoot) {
  ReactDOM.render(<ProductIntelApp />, $productIntelRoot)
}
if ($seoCMSRoot) {
  ReactDOM.render(<SeoCmsApp />, $seoCMSRoot)
}
if ($pricingCMSRoot) {
  ReactDOM.render(<PricingCmsLayout />, $pricingCMSRoot)
}
if ($CMSImagesRoot) {
  ReactDOM.render(<ImagesCmsLayout />, $CMSImagesRoot)
}
if ($CMSAgentsRoot) {
  ReactDOM.render(<AgentsCmsLayout />, $CMSAgentsRoot)
}

/**
 * Misc.
 */
if ($GmailInboxRoot) {
  ReactDOM.render(<GmailInboxWrapper />, $GmailInboxRoot)
}
if ($SalesFunnelRoot) {
  ReactDOM.render(<SalesFunnel />, $SalesFunnelRoot)
}
if ($EmailSignatureRoot) {
  ReactDOM.render(<EmailSignature />, $EmailSignatureRoot)
}
if ($createVCCUploadRequest) {
  ReactDOM.render(<CreateVccUploadRequest />, $createVCCUploadRequest)
}
if ($chFeedbackForm) {
  ReactDOM.render(
    <HashRouter>
      <ChFeedbackForm />
    </HashRouter>,
    $chFeedbackForm
  )
}
if ($RefundsRequestTable) {
  ReactDOM.render(<RefundsTable />, $RefundsRequestTable)
}

if ($tcs) {
  ReactDOM.render(
    <BrowserRouter>
      <TCS />
    </BrowserRouter>,
    $tcs
  )
}
if ($pdfViewer) {
  ReactDOM.render(<PDFViewer />, $pdfViewer)
}
if ($gstInvoice) {
  ReactDOM.render(
    <BrowserRouter>
      <GstInvoice />
    </BrowserRouter>,
    $gstInvoice
  )
}
if (document.getElementById('ticketing-dashboard-overview')) {
  ReactDOM.render(<TicketingOverview />, document.getElementById('ticketing-dashboard-overview'))
}
