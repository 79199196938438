import {
  OPEN_CALL_NOTES,
  CLOSE_CALL_NOTES,
  SET_STATUS_CHANGE_REASON,
} from '../actions/callNotes'

export default function(
  state = {
    currentCallNotesTrailId: '',
    target: '',
    statusChangeReason: [],
  },
  action
) {
  switch (action.type) {
    case OPEN_CALL_NOTES: {
      return {
        ...state,
        currentCallNotesTrailId: action.payload.trailId,
        target: action.payload.target,
      }
    }
    case CLOSE_CALL_NOTES: {
      return {
        ...state,
        currentCallNotesTrailId: '',
        table: '',
      }
    }
    case SET_STATUS_CHANGE_REASON: {
      return {
        ...state,
        statusChangeReason: action.payload,
      }
    }
    default:
      return state
  }
}
