const API_URL = window.env.API_URL
const V3_API = window.env.V3_API_URL
const V4_API = window.env.V4_API_URL
const AUTH_API = window.env.AUTH_API_URL
const CMS_API_STAGING_URL = window.env.PRODUCT_STAGING_API_URL
const CMS_API_PROD_URL = window.env.PRODUCT_API_URL

const API_BASE = 'platosave'
const GMAIL_API_BASE = 'platocommon'
const CMS_API_BASE = 'cms/api'
const CMS_PRODUCT_BASE = '/pickyourtrail/cms/product'
const GUIDES_CMS_API_STAGING_URL = window.env.GUIDES_STAGING_API_URL
const GUIDES_CMS_API_PROD_URL = window.env.GUIDES_API_URL

export const AppConfig = {
  api_url: API_URL,
  veho_api_url: CMS_API_PROD_URL,
  api_base: API_BASE,
  v3_api: V3_API,
  v4_api: V4_API,
  auth_api: AUTH_API,
  gmail_api_base: GMAIL_API_BASE,
  cms_api_base: CMS_API_BASE,
  cms_api_staging_url: CMS_API_STAGING_URL,
  cms_api_prod_url: CMS_API_PROD_URL,
  cms_product_base: CMS_PRODUCT_BASE,
  guides_cms_api_staging: GUIDES_CMS_API_STAGING_URL,
  guides_cms_api_prod: GUIDES_CMS_API_PROD_URL,
  MapboxAccessToken:
    'pk.eyJ1IjoicGlja3lvdXJ0cmFpbCIsImEiOiJjajQzMTVzNXMwbW8wMzJxcTB5c2E0YmMzIn0.my7KPj7RArbSluK19U2cug',
  images_cdn_url: '//dig82prjykzgf.cloudfront.net/',
  images_cdn_misc_url: '//d3lf10b5gahyby.cloudfront.net/misc/',
  CLOUD_URL: '//d3lf10b5gahyby.cloudfront.net/',
  booking_state_alert: 'The itinerary has already been booked. Cant make any further changes.',
  AWS_SECRET_KEY_ID: 'AKIAJNLPJKQPUTDR4I7A',
  AWS_SECRET_ACCESS_KEY: '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',
  AWS_IMAGE_DUMP_BUCKET: 'https://s3.ap-south-1.amazonaws.com/',
  AWS_IMAGE_BUCKET: 'oceanjar-new',
  imageUploadDimension: {
    cities: { width: 1200, height: 600 },
    activities: { width: 1200, height: 600 },
    activityTransfer: { width: 500, height: 500 },
    campaignlogoUrl: { width: 288, height: 116 },
    campaignItineraries: { width: 640, height: 640 },
    SpeedBoatResort: { width: 1000, height: 400 },
    campaign: { width: 1920, height: 600 },
    campaignVideo: { width: 1400, height: 300 },
    campaignMobileVideo: { width: 1400, height: 300 },
    dealMobileCampaign: { width: 368, height: 400 },
    aboutUs: { width: 240, height: 120 },
    companylogo: { width: 240, height: 120 },
    ogImage: { width: 1200, height: 630 },
    careerTeamPrimary: { width: 465, height: 240 },
    careerTeamSecondary: { width: 368, height: 228 },
    campaignSpecialist: { width: 486, height: 456 },
    emailSignatureProfile: { width: 100, height: 100 },
    airportTerminal: { width: 400, height: 400 },
    dealsAdSectionImage: {
      dealsLongAdImageBanner: {
        desktop: { width: 770, height: 260 },
        mobile: { width: 347, height: 388 },
      },
      dealsShortAdImageBanner: { width: 368, height: 260 },
    },
  },
}
