import React from 'react'
import ReactDOM from 'react-dom'

import loadable from './legacy/components/loadable'
import { store, persistor } from './redux/store'

import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import socket from './utils/socket'
import notification from './utils/notification'

import './legacy/less/tailwind.less'
import ErrorBoundary from './components/errorBoundry'

require('./legacy')

socket()
notification.requestPermission()

const Ticketing = loadable(() => import(/* webpackChunkName: "ticketing" */ './ticketing'))
const Presales = loadable(() => import(/* webpackChunkName: "presales" */ './presales'))
const Hr = loadable(() => import(/* webpackChunkName: "hr" */ './hr'))
const Refunds = loadable(() => import(/* webpackChunkName: "refunds" */ './refunds'))
const Supply = loadable(() => import(/* webpackChunkName: "supply" */ './supply'))
const Affiliates = loadable(() => import(/* webpackChunkName: "affiliates" */ './affiliates'))
const Sales = loadable(() => import(/* webpackChunkName: "sales" */ './sales'))
const CxHandover = loadable(() => import(/* webpackChunkName: "lead-allocation" */ './cx-handover'))
const RefundView = loadable(() => import(/* webpackChunkName: "lead-allocation" */ './refund-view'))
const OneTrailView = loadable(() => import(/* webpackChunkName: "one-trail-view" */ './one-trail-view'))
const LeadAllocation = loadable(() => import(/* webpackChunkName: "lead-allocation" */ './lead-allocation'))
const Collections = loadable(() => import(/* webpackChunkName: "lead-allocation" */ './collections'))
const InTrail = loadable(() => import(/* webpackChunkName: "intrail" */ './inTrail'))
const NewTicketing = loadable(() => import(/* webpackChunkName: "new-ticketing" */ './new-ticketing'))
const AuditCx = loadable(() => import(/* webpackChunkName: "new-ticketing" */ './audit-cx'))
const Finance = loadable(() => import(/* webpackChunkName: "new-ticketing" */ './finance'))
const BookingsDashboard = loadable(() => import(/* webpackChunkName: "bookings" */ './bookings'))
const Cx = loadable(() => import(/* webpackChunkName: "bookings" */ './cx'))
const Login = loadable(() => import(/* webpackChunkName: "login" */ './auth/login'))
const Logout = loadable(() => import(/* webpackChunkName: "logout" */ './auth/logout'))

if (document.getElementById('presales-dashboard')) {
  ReactDOM.render(
    <HashRouter>
      <Presales />
    </HashRouter>,
    document.getElementById('presales-dashboard')
  )
}
if (document.getElementById('ticketing-dashboard')) {
  ReactDOM.render(
    <HashRouter>
      <Ticketing />
    </HashRouter>,
    document.getElementById('ticketing-dashboard')
  )
}
if (document.getElementById('hr-dashboard')) {
  ReactDOM.render(
    <HashRouter>
      <Hr />
    </HashRouter>,
    document.getElementById('hr-dashboard')
  )
}
if (document.getElementById('refunds-dashboard')) {
  ReactDOM.render(
    <HashRouter>
      <Refunds />
    </HashRouter>,
    document.getElementById('refunds-dashboard')
  )
}
if (document.getElementById('supply-dashboard')) {
  ReactDOM.render(
    <HashRouter>
      <Supply />
    </HashRouter>,
    document.getElementById('supply-dashboard')
  )
}
if (document.getElementById('affiliates-dashboard')) {
  ReactDOM.render(
    <HashRouter>
      <Affiliates />
    </HashRouter>,
    document.getElementById('affiliates-dashboard')
  )
}

if (document.getElementById('sales-dashboard')) {
  ReactDOM.render(
    <ErrorBoundary>
      <HashRouter>
        <Sales />
      </HashRouter>
    </ErrorBoundary>,
    document.getElementById('sales-dashboard')
  )
}
if (document.getElementById('lead-allocation')) {
  ReactDOM.render(
    <ErrorBoundary>
      <HashRouter>
        <LeadAllocation />
      </HashRouter>
    </ErrorBoundary>,
    document.getElementById('lead-allocation')
  )
}
if (document.getElementById('inTrail-dashboard')) {
  ReactDOM.render(
    <ErrorBoundary>
      <HashRouter>
        <InTrail />
      </HashRouter>
    </ErrorBoundary>,
    document.getElementById('inTrail-dashboard')
  )
}
if (document.getElementById('cx-dashboard')) {
  ReactDOM.render(
    <ErrorBoundary>
      <HashRouter>
        <Cx />
      </HashRouter>
    </ErrorBoundary>,
    document.getElementById('cx-dashboard')
  )
}
if (document.getElementById('cx-handover')) {
  ReactDOM.render(
    <ErrorBoundary>
      <HashRouter>
        <CxHandover />
      </HashRouter>
    </ErrorBoundary>,
    document.getElementById('cx-handover')
  )
}
if (document.getElementById('refunds-view')) {
  ReactDOM.render(
    <ErrorBoundary>
      <HashRouter>
        <RefundView />
      </HashRouter>
    </ErrorBoundary>,
    document.getElementById('refunds-view')
  )
}
if (document.getElementById('one-trail-view')) {
  ReactDOM.render(
    <ErrorBoundary>
      <HashRouter>
        <OneTrailView />
      </HashRouter>
    </ErrorBoundary>,
    document.getElementById('one-trail-view')
  )
}
if (document.getElementById('new-ticketing-dashboard')) {
  ReactDOM.render(
    <ErrorBoundary>
      <HashRouter>
        <NewTicketing />
      </HashRouter>
    </ErrorBoundary>,
    document.getElementById('new-ticketing-dashboard')
  )
}
if (document.getElementById('collections-dashboard')) {
  ReactDOM.render(
    <ErrorBoundary>
      <HashRouter>
        <Collections />
      </HashRouter>
    </ErrorBoundary>,
    document.getElementById('collections-dashboard')
  )
}
if (document.getElementById('audit-cx-dashboard')) {
  ReactDOM.render(
    <ErrorBoundary>
      <HashRouter>
        <AuditCx />
      </HashRouter>
    </ErrorBoundary>,
    document.getElementById('audit-cx-dashboard')
  )
}
if (document.getElementById('finance-dashboard')) {
  ReactDOM.render(
    <ErrorBoundary>
      <HashRouter>
        <Finance />
      </HashRouter>
    </ErrorBoundary>,
    document.getElementById('finance-dashboard')
  )
}
if (document.getElementById('bookings-dashboard')) {
  ReactDOM.render(
    <ErrorBoundary>
      <HashRouter>
        <BookingsDashboard />
      </HashRouter>
    </ErrorBoundary>,
    document.getElementById('bookings-dashboard')
  )
}
/**
 * Auth
 */

if (document.getElementById('auth-login')) {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Login />
      </PersistGate>
    </Provider>,
    document.getElementById('auth-login')
  )
}
if (document.getElementById('auth-logout')) {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Logout />
      </PersistGate>
    </Provider>,
    document.getElementById('auth-logout')
  )
}
