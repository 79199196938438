// import $ from 'jquery';
import { AppConfig } from '../../app-config'

const API_URL = AppConfig.api_url
const API_BASE = AppConfig.api_base

export const FETCHING_ITINERARY = 'FETCHING_ITINERARY'
export const FETCHING_ITINERARY_PENDING = 'FETCHING_ITINERARY_PENDING'
export const COSTING_SUCCESS = 'COSTING_SUCCESS'
export const FETCHING_ITINERARY_ERROR = 'FETCHING_ITINERARY_ERROR'

export const CREATE_ITINERARY = 'CREATE_ITINERARY'
export const CREATE_ITINERARY_ERROR = 'CREATE_ITINERARY_ERROR'

export const START_SESSION = 'START_SESSION'
export const START_SESSION_ERROR = 'START_SESSION_ERROR'

export const STOP_SESSION = 'STOP_SESSION'
export const STOP_SESSION_ERROR = 'STOP_SESSION_ERROR'

// Day actions
export const ADD_DAY = 'ADD_DAY'
export const ADD_DAY_ERROR = 'ADD_DAY_ERROR'
export const REMOVE_DAY = 'REMOVE_DAY'
export const REMOVE_DAY_ERROR = 'REMOVE_DAY_ERROR'

// City Actions
export const ADD_CITY = 'ADD_CITY'
export const ADD_CITY_ERROR = 'ADD_CITY_ERROR'
export const REMOVE_CITY = 'REMOVE_CITY'
export const REMOVE_CITY_ERROR = 'REMOVE_CITY_ERROR'

// Slot Actions
export const EDIT_SLOT = 'EDIT_SLOT'
export const EDIT_SLOT_ERROR = 'EDIT_SLOT_ERROR'

// Costing Actions
export const GET_COST = 'GET_COST'
export const GET_COST_ERROR = 'GET_COST_ERROR'
export const EDIT_COSTLINE = 'EDIT_COSTLINE'
export const EDIT_COSTLINE_ERROR = 'EDIT_COSTLINE_ERROR'
export const REMOVE_COSTLINE = 'REMOVE_COSTLINE'
export const REMOVE_COSTLINE_ERROR = 'REMOVE_COSTLINE_ERROR'
export const ADD_COST = 'ADD_COST'
export const ADD_COST_ERROR = 'ADD_COST_ERROR'

export const UPDATE_HOTEL = 'UPDATE_HOTEL'
export const UPDATE_HOTEL_ERROR = 'UPDATE_HOTEL_ERROR'
export const CHANGE_ROOM = 'CHANGE_ROOM'
export const CHANGE_ROOM_ERROR = 'CHANGE_ROOM_ERROR'
export const UPDATE_FLIGHT = 'UPDATE_FLIGHT'
export const UPDATE_FLIGHT_ERROR = 'UPDATE_FLIGHT_ERROR'

export const ADD_PLANNER_NOTES = 'ADD_PLANNER_NOTES'
export const ADD_PLANNER_NOTES_ERROR = 'ADD_PLANNER_NOTES_ERROR'
export const CREATE_DUPLICATE = 'CREATE_DUPLICATE'
export const CREATE_DUPLICATE_ERROR = 'CREATE_DUPLICATE_ERROR'

export const UPDATE_EXECUTION = 'UPDATE_EXECUTION'
export const UPDATE_EXECUTION_ERROR = 'UPDATE_EXECUTION_ERROR'

export const EDIT_TRAIL_DETAILS = 'EDIT_TRAIL_DETAILS'
export const EDIT_TRAIL_DETAILS_ERROR = 'EDIT_TRAIL_DETAILS_ERROR'

export const ADD_BAD_EXPERIENCE = 'ADD_BAD_EXPERIENCE'
export const ADD_BAD_EXPERIENCE_ERROR = 'ADD_BAD_EXPERIENCE_ERROR'

export const CHANGE_HOD = 'CHANGE_HOD'
export const CHANGE_HOD_ERROR = 'CHANGE_HOD_ERROR'

export const ADD_EXTRA_COST = 'ADD_EXTRA_COST'
export const ADD_EXTRA_COST_ERROR = 'ADD_EXTRA_COST_ERROR'

export const UPDATE_COMMISSION = 'UPDATE_COMMISSION'
export const UPDATE_COMMISSION_ERROR = 'UPDATE_COMMISSION_ERROR'

export const GET_LOSS_REASON = 'GET_LOSS_REASON'
export const GET_LOSS_REASON_ERROR = 'GET_LOSS_REASON_ERROR'
export const DEAL_SIZE_CHANGE_ERROR = 'DEAL_SIZE_CHANGE_ERROR'

export const BOOKING_COST_REMARKS = 'BOOKING_COST_REMARKS'
export const BOOKING_COST_REMARKS_ERROR = 'BOOKING_COST_REMARKS_ERROR'
const $ = window.$

// function handleResponse (response) {
//   let contentType = response.headers.get('content-type')
//   if (contentType.includes('application/json')) {
//     return handleJSONResponse(response)
//   } else if (contentType.includes('text/html')) {
//     return handleTextResponse(response)
//   } else {
//     // Other response types as necessary. I haven't found a need for them yet though.
//     throw new Error(`Sorry, content-type ${contentType} not supported`)
//   }
// }
//
// function handleJSONResponse (response) {
//   return response.json()
//     .then(json => {
//       if (response.ok) {
//         return json
//       } else {
//         return Promise.reject(Object.assign({}, json, {
//           status: response.status,
//           statusText: response.statusText
//         }))
//       }
//     })
// }
//
// function handleTextResponse (response) {
//   return response.text()
//     .then(text => {
//       if (response.ok) {
//         return text
//       } else {
//         return Promise.reject({
//           status: response.status,
//           statusText: response.statusText,
//           err: text
//         })
//       }
//     })
// }

function triggerAjax(dispatch, url, req_data, errorType) {
  $.ajax({
    type: 'POST',
    url: url,
    data: JSON.stringify(req_data),
    success: function(data) {
      dispatch({
        type: FETCHING_ITINERARY,
        payload: { data: data },
      })

      if (data?.newlyCreatedLineItem) {
        // To get the costing ID of newly created Line Item & storing it in localstorage.
        localStorage.setItem('newlyCreatedLineItem', data?.newlyCreatedLineItem)
      }
    },
    error: function(e) {
      console.log('Got error on: ' + errorType)
      console.log(e)

      dispatch({
        type: errorType,
        payload: e,
      })
    },
  })
}

export function getItineraryPromisified(trail_id, copy = 0, source = null) {
  return function(dispatch) {
    return new Promise((resolve, reject) => {
      if (source === 'afterCosting') {
        dispatch({
          type: COSTING_SUCCESS,
          payload: {},
        })
      } else {
        dispatch({
          type: FETCHING_ITINERARY_PENDING,
          payload: {},
        })
      }

      // let data = "trail_id="+trail_id;
      let url = `${API_URL + API_BASE}/getTrailItinerary`
      let req_data = { trail_id: trail_id, copy: copy }
      $.ajax({
        type: 'POST',
        url: url,
        data: JSON.stringify(req_data),
        success: function(data) {
          dispatch({
            type: FETCHING_ITINERARY,
            payload: { data: data },
          })
          resolve(data)
        },
        error: function(e) {
          dispatch({
            type: FETCHING_ITINERARY_ERROR,
            payload: e,
          })
          reject()
        },
      })
    })
  }
}

export function getItinerary(trail_id, copy = 0, source = null) {
  return function(dispatch) {
    if (source === 'afterCosting') {
      dispatch({
        type: COSTING_SUCCESS,
        payload: {},
      })
    } else {
      dispatch({
        type: FETCHING_ITINERARY_PENDING,
        payload: {},
      })
    }

    // let data = "trail_id="+trail_id;
    let url = `${API_URL + API_BASE}/getTrailItinerary`
    let req_data = { trail_id: trail_id, copy: copy }
    triggerAjax(dispatch, url, req_data, FETCHING_ITINERARY_ERROR)

    // // console.log(req_data);
    // const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
    // axios.post(url,req_data)
    //     .then(response => {
    //       dispatch({
    //         type: FETCHING_ITINERARY,
    //         payload: response
    //       });
    //     })
    //     .catch(e => {
    // 			if(e.response && e.response.status == 401){
    // 				default_unauth_alert();
    // 	  	}else{
    // 	  		console.log("unknown exception processing itinerary ")
    // 	  		console.log(e);
    // 	  		default_error_alert();
    // 	  	}
    //
    //       dispatch({
    //         type: FETCHING_ITINERARY_ERROR,
    //         payload: e
    //       });
    //     });

    // Simple response handling
    // let req_data = {"trail_id":trail_id};
    // fetch(url,{
    // 	method: 'POST',
    // 	body: req_data,
    // 	// headers: {
    //   //   "Content-Type": "application/json"
    //   // }
    // }).then(handleResponse)
    // .then(function(response) {
    // 	dispatch({
    //     type: FETCHING_ITINERARY,
    //     payload: {data:response}
    //   });
    // }).catch(function(err) {
    // 	// Error :(
    // 	console.log("unknown exception processing itinerary ")
    // 	console.log(err);
    // });

    // $.ajax({
    //   type: "POST",
    //   url: url,
    //   data: req_data,
    //   success: function(data){
    // 		// console.log(data);
    //     dispatch({
    //       type: FETCHING_ITINERARY,
    //       payload: {data:data}
    //     });
    // 	},
    //   error: function(e){
    //     dispatch({
    //       type: FETCHING_ITINERARY_ERROR,
    //       payload: e
    //     });
    //   }
    // });
  }
}

export function createItinerary(req_data) {
  return function(dispatch) {
    dispatch({
      type: CREATE_ITINERARY,
      payload: {},
    })

    let url = `${API_URL + API_BASE}/createItinerary`
    // console.log(url);
    // console.log(req_data);
    triggerAjax(dispatch, url, req_data, CREATE_ITINERARY_ERROR)
  }
}

export function insertBookingCostHighereReason(req_data) {
  return function(dispatch) {
    dispatch({
      type: BOOKING_COST_REMARKS,
      payload: {},
    })

    let url = `${API_URL + API_BASE}/addHighBookingCostRemarks`
    console.log(url)
    console.log(req_data)
    triggerAjax(dispatch, url, req_data, BOOKING_COST_REMARKS_ERROR)
  }
}

export function startSesstion(req_data) {
  return function(dispatch) {
    dispatch({
      type: START_SESSION,
      payload: {},
    })

    let url = `${API_URL + API_BASE}/sessionStart`
    triggerAjax(dispatch, url, req_data, START_SESSION_ERROR)
  }
}

export function stopSession(req_data) {
  return function(dispatch) {
    dispatch({
      type: STOP_SESSION,
      payload: {},
    })

    let url = `${API_URL + API_BASE}/sessionStop`
    triggerAjax(dispatch, url, req_data, STOP_SESSION_ERROR)
  }
}

export function addDay(req_data) {
  return function(dispatch) {
    dispatch({
      type: ADD_DAY,
      payload: {},
    })

    let url = `${API_URL + API_BASE}/addDay`
    triggerAjax(dispatch, url, req_data, ADD_DAY_ERROR)
  }
}

export function removeDay(req_data) {
  return function(dispatch) {
    dispatch({
      type: REMOVE_DAY,
      payload: {},
    })

    let url = `${API_URL + API_BASE}/removeDay`
    triggerAjax(dispatch, url, req_data, REMOVE_DAY_ERROR)
  }
}

export function addCity(req_data) {
  return function(dispatch) {
    dispatch({
      type: ADD_CITY,
      payload: {},
    })

    let url = `${API_URL + API_BASE}/addCity`
    triggerAjax(dispatch, url, req_data, ADD_CITY_ERROR)
  }
}

export function removeCity(req_data) {
  return function(dispatch) {
    dispatch({
      type: REMOVE_CITY,
      payload: {},
    })

    let url = `${API_URL + API_BASE}/removeCity`
    triggerAjax(dispatch, url, req_data, REMOVE_CITY_ERROR)
  }
}

export function editSlot(req_data) {
  return function(dispatch) {
    dispatch({
      type: EDIT_SLOT,
      payload: {},
    })

    let url = `${API_URL + API_BASE}/editDay`
    triggerAjax(dispatch, url, req_data, EDIT_SLOT_ERROR)
  }
}

export function getCost(req_data, skip_getCost = false) {
  return function(dispatch) {
    dispatch({
      type: GET_COST,
      payload: {},
    })

    // All get cost calls
    let requests = []
    let url = `${API_URL + API_BASE}`
    let getCostReqData = {
      trail_id: req_data.trail_id,
      room_info: req_data.room_info,
    }

    if (!skip_getCost) {
      url = `${API_URL + API_BASE}/getCost`

      requests.push({ url, req_data: getCostReqData })
    }

    // Commutes
    let flightReqData = {}
    req_data.flight_commute &&
      req_data.flight_commute.map(flight => {
        url = `${API_URL + API_BASE}/getFlights`
        flightReqData = Object.assign({}, getCostReqData, flight)

        // console.log(flightReqData);
        requests.push({ url, req_data: flightReqData })

        return null
      })

    // Stay
    let stayReqData = {}
    req_data.stayConfigs &&
      req_data.stayConfigs.map(stay => {
        url = `${API_URL + API_BASE}/getHotels`
        stayReqData = Object.assign({}, getCostReqData, stay)

        // console.log(stayReqData);
        requests.push({ url, req_data: stayReqData })

        return null
      })

    let promises = requests.map(function(val) {
      return fetch(val.url, {
        method: 'POST',
        body: JSON.stringify(val.req_data),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(function(response) {
        return response
      })
    })

    return Promise.all(promises)
      .then(() => {
        // console.log(`Urls were grabbed`);
        // debugger;
        // getItinerary(req_data.trail_id)

        let url = `${API_URL + API_BASE}/getTrailItinerary`
        let get_itin_req_data = { trail_id: req_data.trail_id, copy: 0 }
        triggerAjax(dispatch, url, get_itin_req_data, FETCHING_ITINERARY_ERROR)
      })
      .catch(function(error) {
        // return a promise to the caller
        console.log(error)
      })
  }
}

export function addCostLine(req_data) {
  return function(dispatch) {
    dispatch({
      type: ADD_COST,
      payload: {},
    })

    // console.log(req_data);
    let url = `${API_URL + API_BASE}/addCostType`
    triggerAjax(dispatch, url, req_data, ADD_COST_ERROR)
  }
}

export function updateCostLine(req_data) {
  return function(dispatch) {
    dispatch({
      type: EDIT_COSTLINE,
      payload: {},
    })

    // console.log(req_data);
    let url = `${API_URL + API_BASE}/costLineEdit`
    triggerAjax(dispatch, url, req_data, EDIT_COSTLINE_ERROR)
  }
}

export function removeCostLine(req_data) {
  return function(dispatch) {
    dispatch({
      type: REMOVE_COSTLINE,
      payload: {},
    })

    // console.log(req_data);
    let url = `${API_URL + API_BASE}/deleteCostType`
    triggerAjax(dispatch, url, req_data, REMOVE_COSTLINE_ERROR)
  }
}
export function removeCostLineVoucher(req_data) {
  return function(dispatch) {
    dispatch({
      type: REMOVE_COSTLINE,
      payload: {},
    })

    // console.log(req_data);
    let url = `${API_URL + API_BASE}/removeCostVoucher`
    triggerAjax(dispatch, url, req_data, REMOVE_COSTLINE_ERROR)
  }
}
export function removeHotelCostLine(req_data) {
  return function(dispatch) {
    dispatch({
      type: REMOVE_COSTLINE,
      payload: {},
    })

    // console.log(req_data);
    let url = `${API_URL + API_BASE}/deleteHotelInclution`
    triggerAjax(dispatch, url, req_data, REMOVE_COSTLINE_ERROR)
  }
}

export function updateHotel(req_data) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_HOTEL,
      payload: {},
    })

    // console.log(req_data);
    let url = `${API_URL + API_BASE}/UpdateSelectedHotel`
    triggerAjax(dispatch, url, req_data, UPDATE_HOTEL_ERROR)
  }
}

export function updateRoom(req_data) {
  return function(dispatch) {
    dispatch({
      type: CHANGE_ROOM,
      payload: {},
    })

    // console.log(req_data);
    let url = `${API_URL + API_BASE}/updateSelectedRoom`
    triggerAjax(dispatch, url, req_data, CHANGE_ROOM_ERROR)
  }
}

export function updateFlight(req_data) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_FLIGHT,
      payload: {},
    })

    // console.log(req_data);
    let url = `${API_URL + API_BASE}/saveSelectedFlight`
    triggerAjax(dispatch, url, req_data, UPDATE_FLIGHT_ERROR)
  }
}

export function addPlannerNotes(req_data) {
  return function(dispatch) {
    dispatch({
      type: ADD_PLANNER_NOTES,
      payload: {},
    })

    // console.log(req_data);
    let url = `${API_URL + API_BASE}/addPlannerNotes`
    triggerAjax(dispatch, url, req_data, ADD_PLANNER_NOTES_ERROR)
  }
}

export function createDuplicate(req_data) {
  return function(dispatch) {
    dispatch({
      type: CREATE_DUPLICATE,
      payload: {},
    })

    // console.log(req_data);
    let url = `${API_URL + API_BASE}/makeDuplicate`
    triggerAjax(dispatch, url, req_data, CREATE_DUPLICATE_ERROR)
  }
}

export function updateExecution(req_data) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_EXECUTION,
      payload: {},
    })

    // console.log(req_data);
    let url = `${API_URL + API_BASE}/UpdateExecution`
    triggerAjax(dispatch, url, req_data, UPDATE_EXECUTION_ERROR)
  }
}

export function editTrailDetails(req_data) {
  return function(dispatch) {
    dispatch({
      type: EDIT_TRAIL_DETAILS,
      payload: {},
    })

    // console.log(req_data);
    let url = `${API_URL + API_BASE}/editTrailDetails`
    triggerAjax(dispatch, url, req_data, EDIT_TRAIL_DETAILS_ERROR)
  }
}

export function editCommunicationMethod(req_data) {
  return function(dispatch) {
    dispatch({
      type: EDIT_TRAIL_DETAILS,
      payload: {},
    })

    // console.log(req_data);
    let url = `${API_URL + API_BASE}/saveCustomerCommuticationMode`
    triggerAjax(dispatch, url, req_data, EDIT_TRAIL_DETAILS_ERROR)
  }
}

export function updateTrailStage(req_data) {
  return function(dispatch) {
    dispatch({
      type: EDIT_TRAIL_DETAILS,
      payload: {},
    })

    // console.log(req_data);
    let url = `${API_URL + API_BASE}/updateTrailStatus`
    triggerAjax(dispatch, url, req_data, EDIT_TRAIL_DETAILS_ERROR)
  }
}

export function dealSizeChangeModal(req_data) {
  return function(dispatch) {
    dispatch({
      type: ADD_BAD_EXPERIENCE,
      payload: {},
    })

    // console.log(req_data);
    let url = `${API_URL + API_BASE}/EditDealSize`
    triggerAjax(dispatch, url, req_data, DEAL_SIZE_CHANGE_ERROR)
  }
}

export function addBadExperienceCost(req_data) {
  return function(dispatch) {
    dispatch({
      type: ADD_BAD_EXPERIENCE,
      payload: {},
    })

    // console.log(req_data);
    let url = `${API_URL + API_BASE}/BadExperienceCRUD`
    triggerAjax(dispatch, url, req_data, ADD_BAD_EXPERIENCE_ERROR)
  }
}

export function changeHOD(req_data) {
  return function(dispatch) {
    dispatch({
      type: CHANGE_HOD,
      payload: {},
    })

    // console.log(req_data);
    let url = `${API_URL + API_BASE}/updateHandoverDossier`
    triggerAjax(dispatch, url, req_data, CHANGE_HOD_ERROR)
  }
}

export function addExtraCost(req_data) {
  return function(dispatch) {
    dispatch({
      type: ADD_EXTRA_COST,
      payload: {},
    })

    // console.log(req_data);
    let url = `${API_URL + API_BASE}/AddExtraCostItem`
    triggerAjax(dispatch, url, req_data, ADD_EXTRA_COST_ERROR)
  }
}

export function updateCommissionFees(req_data) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_COMMISSION,
      payload: {},
    })

    // console.log(req_data)
    let url = `${API_URL + API_BASE}/updateCommissionFees`
    triggerAjax(dispatch, url, req_data, UPDATE_COMMISSION_ERROR)
  }
}

export function updateCostHistory(req_data) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_COMMISSION,
      payload: {},
    })

    // console.log(req_data)
    let url = `${API_URL + API_BASE}/UpdateCostHistory`
    triggerAjax(dispatch, url, req_data, UPDATE_COMMISSION_ERROR)
  }
}

export function addInstallment(req_data) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_COMMISSION,
      payload: {},
    })

    // console.log(req_data)
    let url = `${API_URL + API_BASE}/UpdateCostHistory`
    triggerAjax(dispatch, url, req_data, UPDATE_COMMISSION_ERROR)
  }
}

export function getLossReason(req_data) {
  return function(dispatch) {
    dispatch({
      type: GET_LOSS_REASON,
      payload: {},
    })

    // console.log(req_data)
    let url = `${API_URL + API_BASE}/GetLossReason`
    triggerAjax(dispatch, url, req_data, GET_LOSS_REASON_ERROR)
  }
}

export function triggerCancelRequest(req_data) {
  return function(dispatch) {
    dispatch({
      type: GET_LOSS_REASON,
      payload: {},
    })

    // console.log(req_data)
    let url = `${API_URL + API_BASE}/saveRefundInfo`
    triggerAjax(dispatch, url, req_data, GET_LOSS_REASON_ERROR)
  }
}
