import queryString from 'qs'
import { store } from '../../redux/store'
import { AppConfig } from '../../legacy/app-config'
import dotGet from '../object/dotGet'
import _typeof from '../typeof'
import axios from 'axios'
export const apiUrl = AppConfig.v3_api
export const apiUrl_V4 = AppConfig.api_url
export const vehoApiUrl = AppConfig.veho_api_url

export const routes = {
  trail: {
    url: 'trail',
    type: 'POST',
    details: {
      url: 'trail',
      type: 'POST',
    },
    update: {
      url: 'trail',
      type: 'PATCH',
    },
  },
  customer: {
    update: {
      url: 'customer',
      type: 'PATCH',
    },
  },
  presales: {
    dashboard: {
      overview: {
        url: 'pre-sales/dashboard/overview',
        type: 'GET',
      },
      closedLeads: {
        url: 'pre-sales/dashboard/closed-leads',
        type: 'GET',
      },
      telephonyLeads: {
        url: 'pre-sales/dashboard/telephony-metrics',
        type: 'GET',
      },
      leadPool: {
        url: 'pre-sales/dashboard/lead-pool-statistics',
        type: 'GET',
      },
      agentPerformance: {
        url: 'pre-sales/dashboard/performance-metrics',
        type: 'GET',
      },
      dialerMetrics: {
        url: 'pre-sales/dashboard/dialer-metrics',
        type: 'GET',
      },
      leadTraffic: {
        url: 'pre-sales/dashboard/lead-traffic',
        type: 'GET',
      },
    },
    data: {
      leadPool: {
        url: 'pre-sales/data/lead-pool',
        type: 'POST',
      },
      closedLeads: {
        url: 'pre-sales/data/closed-leads',
        type: 'POST',
      },
      telephonyMissed: {
        url: 'pre-sales/missedCallsList',
        type: 'POST',
      },
      telephonyAnswered: {
        url: 'pre-sales/answeredCallsList',
        type: 'POST',
      },

      leadFreshness: {
        url: 'pre-sales/data/lead-freshness',
        type: 'POST',
      },
      agentAvailability: {
        url: 'pre-sales/data/agent-availability-list',
        type: 'POST',
      },
    },
    questions: {
      url: 'pre-sales/questionnaire',
      type: 'GET',
      save: {
        url: 'pre-sales/questionnaire',
        type: 'PUT',
      },
    },
  },
  callNotes: {
    save: {
      url: 'trail/call-notes',
      type: 'PUT',
    },
    saveClosed: {
      url: 'trail/call-notes/closed-lead',
      type: 'PUT',
    },
    get: {
      url: 'trail/call-notes',
      type: 'GET',
    },
    shareItinerary: {
      url: 'trail/call-notes/share-itinerary',
      type: 'POST',
    },
    cannedResponse: {
      create: {
        url: 'trail/call-notes/template',
        type: 'PUT',
      },
      update: {
        url: 'trail/call-notes/template',
        type: 'PATCH',
      },
      get: {
        url: 'trail/call-notes/template',
        type: 'POST',
      },
    },
  },
  sales: {
    metrics: {
      url: 'sales/neo/metrics',
      type: 'POST',
      controlMechanism: {
        url: 'sales/neo/metrics/control-mechanism',
        type: 'POST',
      },
    },
    roster: {
      createRoster: {
        url: 'lead-allocation/salesRoster/createUserLeaveLog',
        type: 'POST',
      },
      getRoster: {
        url: 'lead-allocation/salesRoster/getUserLeaveData',
        type: 'POST',
      },
    },
    leadPool: {
      url: 'sales/neo/lead-pool',
      type: 'GET',
      update: {
        url: 'sales/neo/lead-pool',
        type: 'POST',
      },
    },
    lostData: {
      url: 'sales/dashboard/lost-data',
      type: 'GET',
    },
    scoreCard: {
      url: 'sales/dashboard/score-card',
      type: 'GET',
    },
    convertData: {
      url: 'sales/dashboard/convert-data',
      type: 'GET',
    },
    leadsToConversion: {
      url: 'sales/dashboard/leads-to-conversion',
      type: 'GET',
    },
    qualifiedLeadPerformance: {
      url: 'sales/dashboard/qualified-lead-performance',
      type: 'GET',
    },
    leads: {
      url: 'sales/neo/leads',
      type: 'POST',

      counts: {
        url: 'sales/neo/leads/counts',
        type: 'POST',
      },
    },
    incompleteInformation: {
      url: 'sales/neo/leads/incomplete-information',
      type: 'POST',
    },
    leadAllocation: {
      url: 'lead-allocation/allocateLead',
      type: 'POST',
    },
  },
  leadAllocation: {
    newRule: {
      url: 'lead-allocation/saveLeadAllocationConfig',
      type: 'POST',
    },
    getRule: {
      url: 'lead-allocation/rules',
      type: 'POST',
    },
    updateRule: {
      url: 'lead-allocation/updateLeadAllocationConfig',
      type: 'POST',
    },
    getTeam: {
      url: 'lead-allocation/getAllTeamData',
      type: 'POST',
    },
    createTeam: {
      url: 'lead-allocation/saveTeam',
      type: 'POST',
    },
    updateTeam: {
      url: 'lead-allocation/updateTeam',
      type: 'POST',
    },
    maxCap: {
      url: 'lead-allocation/leadAllocationMaxCapView/getAllUsersMaxCapData',
      type: 'POST',
    },
    getManager: {
      url: 'lead-allocation/getTypeManagerList',
      type: 'POST',
    },
    targetsView: {
      url: 'sales/targets',
      type: 'GET',
    },
    editTargets: {
      url: 'sales/targets',
      type: 'PUT',
    },
    numberOfNightsView: {
      url: 'lead-allocation/noOfNights/view',
      type: 'POST',
    },
    editNumberOfNights: {
      url: 'lead-allocation/noOfNights/edit',
      type: 'PUT',
    },
    maxCapLeadPool: {
      url: 'lead-allocation/leadAllocationMaxCapView/editUserLeadPoolStatus',
      type: 'POST',
    },
    maxCapDownload: {
      url: 'lead-allocation/leadAllocationMaxCapView/downloadUserMaxCapData',
      type: 'GET',
    },
    maxCapEdit: {
      url: 'lead-allocation/leadAllocationMaxCapView/editUserMaxCapData',
      type: 'POST',
    },
    leadFill: {
      url: 'lead-allocation/getLeadFillReport',
      type: 'POST',
    },
    customRuleTypes: {
      url: 'lead-allocation/getCustomRuleType',
      type: 'POST',
    },
    getRegions: {
      url: 'lead-allocation/getSubRegionDisplayData',
      type: 'GET',
    },
    getAffiliatesPartner: {
      url: 'lead-allocation/getAffiliateSubType',
      type: 'GET',
    },
    getResort: {
      url: 'common/get-resorts',
      type: 'POST',
    },
    getHierarchy: {
      url: 'sales/hierarchy/getHierarchy',
      type: 'POST',
    },
    updateHierarchy: {
      url: 'sales/hierarchy/updateManagers',
      type: 'POST',
    },
    firstSetAllocation: {
      url: 'lead-allocation/firstSetAllocation/getRuleConfig ',
      type: 'POST',
    },
    insertFirstSet: {
      url: 'lead-allocation/firstSetAllocation/upsertFirstSetConfig',
      type: 'POST',
    },
    removeFirstSet: {
      url: 'lead-allocation/firstSetAllocation/disableFirstSetConfig',
      type: 'POST',
    },
  },
  bookings: {
    getCountAO: {
      url: 'user/getAvailableFloorAO',
      type: 'POST',
    },
    updateLineItemSts: {
      url: 'booking/dashboard/updateLineItemStatus',
      type: 'POST',
    },
  },
  inTrail: {
    getInTrail: {
      url: 'trail/inTrail',
      type: 'POST',
    },
    getUpdatedCount: {
      url: 'trail/inTrail/getTotalIntrailCount',
      type: 'POST',
    },
    saveDailyCommits: {
      url: 'trail/inTrail/updateDailyCommits',
      type: 'PUT',
    },
    saveRemarks: {
      url: 'trail/inTrail/createRemarks',
      type: 'POST',
    },
  },
  refundsView: {
    getRefundsData: {
      url: 'trail/refundLog/getRefundsTable',
      type: 'POST',
    },
    saveRefundsProcessed: {
      url: 'trail/refundLog/markRefunds',
      type: 'PUT',
    },
    saveRefundApproved: {
      url: 'trail/refundLog/markRefundsApproved',
      type: 'PUT',
    },
  },
  ticketing: {
    getAllTickets: {
      url: 'ticketing/tasks',
      type: 'GET',
    },
    overview: {
      url: 'ticketing/task-report',
      type: 'GET',
    },
    createNewTicket: {
      url: 'ticketing/task',
      type: 'PUT',
    },
    getTaskTypes: {
      url: 'ticketing/task/types',
      type: 'GET',
    },
    getTaskSubTypes: {
      url: 'ticketing/task/subtypes',
      type: 'GET',
    },
    getTaskStatus: {
      url: 'ticketing/task/status',
      type: 'GET',
    },
    getAssignee: {
      url: 'ticketing/users',
      type: 'GET',
    },
  },
  supply: {
    dashboard: {
      overview: {
        url: 'supply/dashboard/overview',
        type: 'GET',
      },
      receivables: {
        url: 'supply/dashboard/receivables',
        type: 'GET',
      },
      payables: {
        url: 'supply/dashboard/payables',
        type: 'GET',
      },
    },
    watchlist: {
      url: 'supply/watchlist',
      type: 'GET',
      add: {
        url: 'supply/watchlist/add',
        type: 'POST',
      },
      remove: {
        url: 'supply/watchlist/{id}/remove',
        type: 'POST',
      },
    },
    data: {
      recon: {
        url: 'supply/data/recon',
        type: 'POST',
      },
      details: {
        url: 'supply/data/details',
        type: 'GET',
      },
    },
    supplier: {
      add: {
        url: 'supply/supplier',
        type: 'POST',
      },
      transaction: {
        add: {
          url: 'supply/supplier/transaction',
          type: 'POST',
        },
        markPaid: {
          url: 'supply/supplier/mark-paid',
          type: 'POST',
        },
      },
    },
  },
  refunds: {
    credit: {
      supplierList: {
        url: 'credit-note/supplier-credit-list',
        type: 'POST',
      },
      customerList: {
        url: 'credit-note/customer-credit-list',
        type: 'POST',
      },
      redeem: {
        url: 'credit-note/redemption',
        type: 'POST',
      },

      supplierUpload: {
        url: 'credit-note/supplier-credit',
        type: 'POST',
      },
      customerUpload: {
        url: 'credit-note/customer-credit',
        type: 'POST',
      },
    },
  },
  affiliates: {
    getAffiliates: {
      url: 'affiliate/getAffiliateCount',
      type: 'POST',
    },
    getTrails: {
      url: 'affiliate/getAffiliateList',
      type: 'POST',
    },
  },
  cxhandover: {
    getAllTickets: {
      url: 'cx-handover/getAllTickets',
      type: 'POST',
    },
    getInclusionType: {
      url: 'common/get-all-inclusions',
      type: 'POST',
    },
    createTickets: {
      url: 'cx-handover/saveCXTickets',
      type: 'POST',
    },
    createRemarks: {
      url: 'cx-handover/saveCXTicketsRemarks',
      type: 'POST',
    },
  },
  user: {
    url: 'user',
    type: 'GET',
    all: {
      url: 'user/all',
      type: 'GET',
    },
    create: {
      url: 'user',
      type: 'PUT',
    },
    update: {
      url: 'user',
      type: 'PATCH',
    },
    master: {
      features: {
        url: 'user/master/features',
        type: 'GET',
      },
      roles: {
        url: 'user/master/roles',
        type: 'GET',
      },
    },
  },
  notifications: {
    get: {
      url: 'notifications',
      type: 'GET',
    },
    markAsRead: {
      url: 'notifications/{id}/read',
      type: 'POST',
    },
  },
  master: {
    languages: {
      url: 'common/language/get-all',
      type: 'GET',
    },
    leadSources: {
      url: 'master/lead-sources',
      type: 'GET',
    },
    leadTypes: {
      url: 'master/lead-types',
      type: 'GET',
    },
    prodTypes: {
      url: 'master/prod-types',
      type: 'GET',
    },
    inclusionTypes: {
      url: 'master/inclusion-types',
      type: 'GET',
    },
    regions: {
      url: 'master/regions',
      type: 'GET',
    },
    subRegions: {
      url: 'master/sub-regions',
      type: 'GET',
    },
    cities: {
      url: 'master/cities',
      type: 'GET',
    },
    flyingFrom: {
      url: 'common/departure-city',
      type: 'GET',
    },
    countries: {
      url: 'master/countries',
      type: 'GET',
    },
    currencies: {
      url: 'master/currencies',
      type: 'GET',
    },
    adminRoles: {
      url: 'master/admin-roles',
      type: 'GET',
    },
    roles: {
      url: 'master/roles',
      type: 'GET',
    },
    trailStatuses: {
      url: 'master/trail-statuses',
      type: 'GET',
    },
    callResponses: {
      url: 'master/call-responses',
      type: 'GET',
    },
    reasons: {
      url: 'master/reasons',
      type: 'GET',
    },
    tempHotels: {
      url: 'master/temp-hotels',
      type: 'GET',
    },
    locale: {
      url: 'master/locale',
      type: 'GET',
    },
    trail: {
      statusChange: {
        url: 'master/reasons',
        type: 'GET',
      },
      list: {
        url: 'master/trails',
        type: 'POST',
      },
      openTrails: {
        url: 'master/open-trails',
        type: 'POST',
      },
    },
    supply: {
      bookingTypes: {
        url: 'master/supply/booking-types',
        type: 'GET',
      },
      paymentCycle: {
        url: 'master/supply/payment-cycle',
        type: 'GET',
      },
      paymentTypes: {
        url: 'master/supply/payment-types',
        type: 'GET',
      },
      serviceTypes: {
        url: 'master/supply/service-types',
        type: 'GET',
      },
      suppliers: {
        url: 'master/supply/suppliers',
        type: 'GET',
      },
    },
  },
}

export default function call(options = {}) {
  return new Promise((resolve, reject) => {
    let { route, params, query, body, headers, method, from } = options
    let { url = route, type = method } = dotGet(routes, route) || {}
    if (params && _typeof(params) === 'object') {
      Object.keys(params).forEach(key => {
        url = url.replace(`{${key}}`, params[key])
      })
    }

    if (query && _typeof(query) === 'object') {
      url += `/?${queryString.stringify(query, {
        arrayFormat: 'indices',
        encode: false,
      })}`
    }
    axios({
      method: type,
      url: url.includes('http') ? `${url}` : from === 'v4' ? `${apiUrl_V4}api/v4/${url}` : `${apiUrl}${url}`,
      data: body,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getState().user.access_token}`,
        ...headers,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error))

    // $.ajax({
    //   type: type,
    //   url: `${apiUrl}${url}`,
    //   headers: ,
    //   data: body || '',
    //   success: data => resolve(data),
    //   error: e => reject(e),
    // })
  })
}
