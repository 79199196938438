import call from '../../utils/api'

export const OPEN_CALL_NOTES = 'OPEN_CALL_NOTES'
export const CLOSE_CALL_NOTES = 'CLOSE_CALL_NOTES'
export const SET_STATUS_CHANGE_REASON = 'SET_STATUS_CHANGE_REASON'

export const openCallNotes = (trailId, target) => {
  return {
    type: 'OPEN_CALL_NOTES',
    payload: {
      trailId: trailId,
      target: target,
    },
  }
}

export const closeCallNotes = () => {
  return {
    type: 'CLOSE_CALL_NOTES',
  }
}

export const getStatusChangeReason = trailStatus => {
  return dispatch => {
    call({
      route: 'master.trail.statusChange',
      query: {
        trail_status: trailStatus,
      },
    })
      .then(response => {
        dispatch({
          type: 'SET_STATUS_CHANGE_REASON',
          payload: response,
        })
      })
      .catch(() => {
        dispatch({
          type: 'SET_STATUS_CHANGE_REASON',
          payload: [],
        })
      })
  }
}
