import { SET_USER_DETAILS } from '../actions/user'

export default function(state = {}, action) {
  switch (action.type) {
    case SET_USER_DETAILS: {
      return action.payload
    }
    default:
      return state
  }
}
