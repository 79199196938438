/**
 * typeof.js
 *
 * Returns absolute type of an unknown entitiy.
 * We like to call a spade a spade and not "object" :)
 */

export default unknown => {
  if (typeof unknown === 'undefined') {
    return 'undefined'
  }

  return Object.prototype.toString
    .call(unknown)
    .slice(8, -1)
    .toLowerCase()
}
