import io from 'socket.io-client'
import notification from './notification'
import appendToSearch from './appendToSearch'
import { store } from '../redux/store'
import { openCallNotes } from '../redux/actions/callNotes'

export default () => {
  if (!localStorage.getItem('persist:root')) {
    return
  }

  const userId = JSON.parse(JSON.parse(localStorage.getItem('persist:root')).user).user_id

  if (!userId) {
    return
  }

  const socket = io.connect(`${window.env.SOCKET_URL}?userId=${userId}`, {
    reconnectionAttempts: 1,
  })

  socket.on('connect', () => {
    socket.on(`plato.user.${userId}`, data => {
      handlePush(data)
    })
    socket.on('plato.global', data => {
      handlePush(data)
    })
  })
}

export const handleNotificationClick = data => {
  if (data.ticket) {
    window.open(
      `${window.location.origin}/admin/dashboard/ticketing/#/?${appendToSearch('', {
        notifiable: JSON.stringify(data.ticket),
      })}`
    )
  }
  if (data.trail) {
    window.open(`${window.location.origin}/admin/default/view/id/${data.trail.trail_id}`)
  }
}

const handlePush = data => {
  data = JSON.parse(data)
  // Laravel triggered events need to be
  // handled like this.
  if (data.event && data.data) {
    data = data.data
  }
  switch (data.action) {
    case 'notification':
      notification.notify(data.payload, () => {
        handleNotificationClick(data)
      })
      break
    case 'open-call-notes':
      store.dispatch(openCallNotes(data.payload.trail_id, data.payload.table || 'leadPool'))
      break
    default:
      break
  }
}
