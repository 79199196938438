export const SET_PRESALES_SUBORDINATE_USER_ID =
  'SET_PRESALES_SUBORDINATE_USER_ID'

export const SET_PRESALES_DATA_SELECTION_DATE =
  'SET_PRESALES_DATA_SELECTION_DATE'

export const RELOAD_OVERVIEW = 'RELOAD_OVERVIEW'
export const RELOAD_LD_POOL_TABLE = 'RELOAD_LD_POOL_TABLE'
export const RELOAD_CLOSED_LEADS_TABLE = 'RELOAD_CLOSED_LEADS_TABLE'

export const setPresalesSubordinateUserId = userId => {
  return {
    type: 'SET_PRESALES_SUBORDINATE_USER_ID',
    payload: userId,
  }
}

export const setPresalesDataSelectionDate = date => {
  return {
    type: 'SET_PRESALES_DATA_SELECTION_DATE',
    payload: date,
  }
}

export const reloadOverview = toggle => {
  return {
    type: 'RELOAD_OVERVIEW',
    payload: toggle,
  }
}

export const reloadLeadPool = toggle => {
  return {
    type: 'RELOAD_LD_POOL_TABLE',
    payload: toggle,
  }
}

export const reloadClosedLeads = toggle => {
  return {
    type: 'RELOAD_CLOSED_LEADS_TABLE',
    payload: toggle,
  }
}
