export const NEW_USER_DETAILS = 'NEW_USER_DETAILS'

export const updateNewUser = val => {
  return {
    type: 'NEW_USER_DETAILS',
    payload: {
      email: val,
    },
  }
}
