import { FETCHING_ITINERARY } from 'itinerary/actions/actions_itinerary'

export default function(state = {}, action) {
  switch (action.type) {
    case FETCHING_ITINERARY: {
      if (typeof action.payload.data.extra_cost_items === 'undefined') {
        return state
      } else {
        return action.payload.data.extra_cost_items
      }
    }
    default:
      return state
  }
}
