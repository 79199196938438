import {
  FETCHING_ITINERARY_PENDING,
  COSTING_SUCCESS,
  CREATE_ITINERARY,
  START_SESSION,
  STOP_SESSION,
  ADD_DAY,
  REMOVE_DAY,
  ADD_CITY,
  REMOVE_CITY,
  EDIT_SLOT,
  GET_COST,
  EDIT_COSTLINE,
  REMOVE_COSTLINE,
  ADD_COST,
  UPDATE_HOTEL,
  CHANGE_ROOM,
  UPDATE_FLIGHT,
  ADD_PLANNER_NOTES,
  CREATE_DUPLICATE,
  UPDATE_EXECUTION,
  EDIT_TRAIL_DETAILS,
  ADD_BAD_EXPERIENCE,
  CHANGE_HOD,
  ADD_EXTRA_COST,
  UPDATE_COMMISSION,
  FETCHING_ITINERARY,
  FETCHING_ITINERARY_ERROR,
  CREATE_ITINERARY_ERROR,
  START_SESSION_ERROR,
  STOP_SESSION_ERROR,
  ADD_DAY_ERROR,
  REMOVE_DAY_ERROR,
  ADD_CITY_ERROR,
  REMOVE_CITY_ERROR,
  EDIT_SLOT_ERROR,
  GET_COST_ERROR,
  EDIT_COSTLINE_ERROR,
  REMOVE_COSTLINE_ERROR,
  ADD_COST_ERROR,
  UPDATE_HOTEL_ERROR,
  CHANGE_ROOM_ERROR,
  UPDATE_FLIGHT_ERROR,
  ADD_PLANNER_NOTES_ERROR,
  CREATE_DUPLICATE_ERROR,
  UPDATE_EXECUTION_ERROR,
  EDIT_TRAIL_DETAILS_ERROR,
  ADD_BAD_EXPERIENCE_ERROR,
  CHANGE_HOD_ERROR,
  ADD_EXTRA_COST_ERROR,
  UPDATE_COMMISSION_ERROR,
} from 'itinerary/actions/actions_itinerary'

export default function(state = { isFetching: false, isFetchingError: false }, action) {
  switch (action.type) {
    case FETCHING_ITINERARY_PENDING:
    case COSTING_SUCCESS:
    case CREATE_ITINERARY:
    case START_SESSION:
    case STOP_SESSION:
    case ADD_DAY:
    case REMOVE_DAY:
    case ADD_CITY:
    case REMOVE_CITY:
    case EDIT_SLOT:
    case GET_COST:
    case EDIT_COSTLINE:
    case REMOVE_COSTLINE:
    case ADD_COST:
    case UPDATE_HOTEL:
    case CHANGE_ROOM:
    case UPDATE_FLIGHT:
    case ADD_PLANNER_NOTES:
    case CREATE_DUPLICATE:
    case UPDATE_EXECUTION:
    case EDIT_TRAIL_DETAILS:
    case ADD_BAD_EXPERIENCE:
    case CHANGE_HOD:
    case ADD_EXTRA_COST:
    case UPDATE_COMMISSION:
      return Object.assign({}, state, {
        isFetching: true,
        isFetchingError: false,
        action: action.type,
      })

    case FETCHING_ITINERARY:
      return Object.assign({}, state, {
        isFetching: false,
        isFetchingError: false,
        action: action.type,
      })

    case FETCHING_ITINERARY_ERROR:
    case CREATE_ITINERARY_ERROR:
    case START_SESSION_ERROR:
    case STOP_SESSION_ERROR:
    case ADD_DAY_ERROR:
    case REMOVE_DAY_ERROR:
    case ADD_CITY_ERROR:
    case REMOVE_CITY_ERROR:
    case EDIT_SLOT_ERROR:
    case GET_COST_ERROR:
    case EDIT_COSTLINE_ERROR:
    case REMOVE_COSTLINE_ERROR:
    case ADD_COST_ERROR:
    case UPDATE_HOTEL_ERROR:
    case CHANGE_ROOM_ERROR:
    case UPDATE_FLIGHT_ERROR:
    case ADD_PLANNER_NOTES_ERROR:
    case CREATE_DUPLICATE_ERROR:
    case UPDATE_EXECUTION_ERROR:
    case EDIT_TRAIL_DETAILS_ERROR:
    case ADD_BAD_EXPERIENCE_ERROR:
    case CHANGE_HOD_ERROR:
    case ADD_EXTRA_COST_ERROR:
    case UPDATE_COMMISSION_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        isFetchingError: true,
        action: action.type,
      })
    default:
      return state
  }
}
