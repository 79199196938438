import {
  GET_USERS,
  GET_LEAD_SOURCE,
  GET_REGIONS,
  GET_SUB_REGIONS,
  GET_CITY_LIST,
  GET_STATUS_CHANGE_REASON,
  GET_ADMIN_ROLES,
  GET_FEATURES,
  GET_ROLES,
  GET_LANGUAGES,
} from '../actions/master'

export default function(
  state = {
    users: [],
    features: [],
    leadSource: [],
    regions: [],
    subRegions: [],
    cities: [],
    statusChangeReasons: [],
    adminRoles: [],
    roles: [],
    languages: [],
  },
  action
) {
  switch (action.type) {
    case GET_LEAD_SOURCE: {
      return {
        ...state,
        leadSource: action.payload,
      }
    }
    case GET_REGIONS: {
      return {
        ...state,
        regions: action.payload,
      }
    }
    case GET_SUB_REGIONS: {
      return {
        ...state,
        subRegions: action.payload,
      }
    }
    case GET_CITY_LIST: {
      return {
        ...state,
        cities: action.payload,
      }
    }
    case GET_STATUS_CHANGE_REASON: {
      return {
        ...state,
        statusChangeReasons: action.payload,
      }
    }

    case GET_USERS: {
      return {
        ...state,
        users: action.payload,
      }
    }

    case GET_ADMIN_ROLES: {
      return {
        ...state,
        adminRoles: action.payload,
      }
    }
    case GET_FEATURES: {
      return {
        ...state,
        features: action.payload,
      }
    }
    case GET_ROLES: {
      return {
        ...state,
        roles: action.payload,
      }
    }
    case GET_LANGUAGES: {
      return {
        ...state,
        languages: action.payload,
      }
    }
    default:
      return state
  }
}
