import _typeof from '../typeof'

/**
 * Get the value of given key in object.
 *
 * @param {object} object
 * @param {string} key
 * @returns {*} value
 */
const dotGet = (object, key) => {
  return _typeof(object) !== 'object' || _typeof(key) !== 'string'
    ? undefined
    : key.split('.').reduce((o, i) => o && o[i], object)
}

export default dotGet
