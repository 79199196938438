import {
  SET_TASK_STATUSES,
  SET_TASK_TYPES,
  SET_TASK_SUB_TYPES,
  SET_DASHBOARD_SUBORDINATE,
  REALOD_OVERVIEW,
  REALOD_TICKETS,
} from '../actions/ticketing'

export default function(
  state = {
    taskTypes: [],
    taskStatus: [],
    subordinate: {},
    reloadOverview: false,
    reloadTickets: false,
  },
  action
) {
  switch (action.type) {
    case SET_TASK_STATUSES: {
      return {
        ...state,
        taskStatus: action.payload,
      }
    }
    case SET_TASK_TYPES: {
      return {
        ...state,
        taskTypes: action.payload,
      }
    }
    case SET_TASK_SUB_TYPES: {
      return {
        ...state,
        taskSubTypes: action.payload,
      }
    }
    case SET_DASHBOARD_SUBORDINATE: {
      return {
        ...state,
        subordinate: action.payload,
      }
    }
    case REALOD_TICKETS: {
      return {
        ...state,
        reloadTickets: action.payload,
      }
    }
    case REALOD_OVERVIEW: {
      return {
        ...state,
        reloadOverview: action.payload,
      }
    }
    default:
      return state
  }
}
