import { NEW_USER_DETAILS } from '../actions/newUser'

export default function(
  state = {
    email: '',
  },
  action
) {
  switch (action.type) {
    case NEW_USER_DETAILS: {
      return {
        ...state,
        email: action.payload?.email,
      }
    }
    default:
      return state
  }
}
