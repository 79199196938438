import call from '../../utils/api'
import Axios from 'axios'
import { AppConfig } from '../../legacy/app-config'

export const GET_LEAD_SOURCE = 'GET_LEAD_SOURCE'
export const GET_PROD_TYPE = 'GET_PROD_TYPE'
export const GET_REGIONS = 'GET_REGIONS'
export const GET_SUB_REGIONS = 'GET_SUB_REGIONS'
export const GET_CITY_LIST = 'GET_CITY_LIST'
export const GET_STATUS_CHANGE_REASON = 'GET_STATUS_CHANGE_REASON'

export const GET_USERS = 'GET_USERS'
export const GET_ADMIN_ROLES = 'GET_ADMIN_ROLES'
export const GET_FEATURES = 'GET_FEATURES'
export const GET_ROLES = 'GET_ROLES'
export const GET_LANGUAGES = 'GET_LANGUAGES'

export const getUsers = () => dispatch =>
  call({
    route: 'user.all',
  })
    .then(response => {
      dispatch({
        type: 'GET_USERS',
        payload: response.map(user => ({
          id: user.user_id,
          name: user.username,
          role_id: user.role,
          parent_id: user.parent,
        })),
      })
    })
    .catch(() => {
      dispatch({
        type: 'GET_USERS',
        payload: [],
      })
    })

export const getFeatures = () => dispatch =>
  call({
    route: 'user.master.features',
  })
    .then(response =>
      dispatch({
        type: 'GET_FEATURES',
        payload: response,
      })
    )
    .catch(() =>
      dispatch({
        type: 'GET_USERS',
        payload: [],
      })
    )

export const getAdminRoles = () => dispatch =>
  call({
    route: 'master.adminRoles',
  })
    .then(response =>
      dispatch({
        type: 'GET_ADMIN_ROLES',
        payload: response,
      })
    )
    .catch(() =>
      dispatch({
        type: 'GET_ADMIN_ROLES',
        payload: [],
      })
    )

export const getRoles = () => dispatch =>
  call({
    route: 'user.master.roles',
  })
    .then(response =>
      dispatch({
        type: 'GET_ROLES',
        payload: response,
      })
    )
    .catch(() =>
      dispatch({
        type: 'GET_ROLES',
        payload: [],
      })
    )

export const getLeadSource = () => {
  return dispatch => {
    call({
      route: 'master.leadSources',
    })
      .then(response => {
        dispatch({
          type: 'GET_LEAD_SOURCE',
          payload: response,
        })
      })
      .catch(() => {
        dispatch({
          type: 'GET_LEAD_SOURCE',
          payload: [],
        })
      })
  }
}

export const getRegions = () => {
  return dispatch => {
    call({
      route: 'master.regions',
    })
      .then(response => {
        dispatch({
          type: 'GET_REGIONS',
          payload: response,
        })
      })
      .catch(() => {
        dispatch({
          type: 'GET_REGIONS',
          payload: [],
        })
      })
  }
}

export const getSubRegions = () => {
  return dispatch => {
    call({
      route: 'master.subRegions',
    })
      .then(response => {
        dispatch({
          type: 'GET_SUB_REGIONS',
          payload: response,
        })
      })
      .catch(() => {
        dispatch({
          type: 'GET_SUB_REGIONS',
          payload: [],
        })
      })
  }
}

export const getCityList = () => {
  return dispatch => {
    call({
      route: 'master.cities',
    })
      .then(response => {
        dispatch({
          type: 'GET_CITY_LIST',
          payload: response,
        })
      })
      .catch(() => {
        dispatch({
          type: 'GET_CITY_LIST',
          payload: [],
        })
      })
  }
}

export const getStatusChangeReason = () => {
  return dispatch => {
    call({
      route: 'master.reasons',
    })
      .then(response => {
        dispatch({
          type: 'GET_STATUS_CHANGE_REASON',
          payload: response,
        })
      })
      .catch(() => {
        dispatch({
          type: 'GET_STATUS_CHANGE_REASON',
          payload: [],
        })
      })
  }
}

export const getLanguages = () => {
  return dispatch => {
    call({
      route: 'master.languages',
    })
      .then(response => {
        dispatch({
          type: 'GET_LANGUAGES',
          payload: response,
        })
      })
      .catch(() => {
        dispatch({
          type: 'GET_LANGUAGES',
          payload: [],
        })
      })
  }
}

export const getProdTypes = () => {
  return dispatch => {
    call({
      route: 'master.prodTypes',
    })
      .then(response => {
        dispatch({
          type: 'GET_PROD_TYPE',
          payload: response,
        })
      })
      .catch(() => {
        dispatch({
          type: 'GET_PROD_TYPE',
          payload: [],
        })
      })
  }
}
