import React from 'react'
import loadable from '@loadable/component'

export default (loadFn, options) =>
  loadable(loadFn, {
    ...options,
    fallback: (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        Loading...
      </div>
    ),
  })
