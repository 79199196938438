import {
  SET_PRESALES_SUBORDINATE_USER_ID,
  SET_PRESALES_DATA_SELECTION_DATE,
  RELOAD_CLOSED_LEADS_TABLE,
  RELOAD_LD_POOL_TABLE,
  RELOAD_OVERVIEW,
} from '../actions/presales'

export default function(
  state = {
    subordinateUserId: '',
    dataSelectionDate: '1',
    reloadOverview: false,
    reloadLeadPool: false,
    reloadClosedLeads: false,
  },
  action
) {
  switch (action.type) {
    case SET_PRESALES_SUBORDINATE_USER_ID: {
      return {
        ...state,
        subordinateUserId: action.payload,
      }
    }
    case SET_PRESALES_DATA_SELECTION_DATE: {
      return {
        ...state,
        dataSelectionDate: action.payload,
      }
    }
    case RELOAD_OVERVIEW: {
      return {
        ...state,
        reloadOverview: action.payload,
      }
    }
    case RELOAD_LD_POOL_TABLE: {
      return {
        ...state,
        reloadLeadPool: action.payload,
      }
    }
    case RELOAD_CLOSED_LEADS_TABLE: {
      return {
        ...state,
        reloadClosedLeads: action.payload,
      }
    }
    default:
      return state
  }
}
